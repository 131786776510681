<template>
  <vue-draggable-resizable
      :z="99"
      :resizable="false"
      drag-handle=".drag-area"
      class="widget-container"
      v-click-outside="closeWidget"
      h="auto"
  >
    <div v-if="!loading && task" :style="{backgroundColor: taskType.headerColor}" class="task-view-widget-header drag-area">
      <img
          width="104"
          height="11"
          src="@/assets/images/menu.svg"
          alt=""
      />
      <div style="display: flex; justify-content: flex-end; gap: 12px; flex-wrap: nowrap;">
        <div style="display: flex; flex-wrap: nowrap; gap: 4px;"
             v-if="task.category && (task.category !== 'open_house' && task.category !== 'event' && task.category !== 'resource')">
          <template v-if="!task.isCompleted">
            <img height="16" width="16" :src="require('@/assets/images/checkmark_16_16.svg')" alt=""/>
            <p @click="changeTaskCompleteStatus" class="link-small">{{ $t("MarkCompleted") }}</p>
          </template>
          <template v-if="task.isCompleted">
            <p @click="changeTaskCompleteStatus" class="link-small">{{ $t("Done") }}</p>
          </template>
        </div>
        <p @click="addParticipants" class="link-small" v-if="taskType.value === 'open_house'">{{
            task.numberOfParticipants ? task.numberOfParticipants + " " + $t("Participants") : $t("AddParticipants")
          }}</p>
        <p @click="editTask" v-if="task.category !== 'resource'" class="link-small">{{ $t("Edit") }}</p>
        <p @click="openDeleteModal" class="link-small">{{ $t("Delete") }}</p>
      </div>
    </div>
    <template v-if="!loading && task">
      <div class="task-view-widget-body">
        <div class="task-view-widget-content" v-bind:class="{
          'open-house-content': taskType.value === 'open_house'
        }">
          <div v-if="taskType.value !== 'generic'" :style="{backgroundColor: taskType.color}"
               class="content-small task-view-widget-label">
            {{ $t(taskType.translation) }}
          </div>
          <div class="task-view-widget-content-title">
            <img height="20" width="20" v-if="task.icon || task.category === 'resource'" :src="require(`@/assets/images/${getTaskIconName()}`)"/>
            <div style="display: flex; flex-direction: column; gap: 4px;">
              <div class="task-view-widget-content-header" v-bind:class="{
                'open-house-gap': task.category === 'open_house'
              }">{{ task.content ? task.content : task.linkedProject ? task.linkedProject.name : task.linkedDevelopment.name }}</div>
              <div v-if="taskType.value === 'resource'" style="color: #75787a" class="content-small">{{ taskType.dropdownItems?.find(item => item._id === task.resourceId)?.description }}</div>
            </div>
          </div>
          <div
              v-if="Object.values(task.dates).some((value) => value !== null) || (task.linkedProject && task.linkedProject.name && task.linkedProject.id) || (task.linkedDevelopment && task.linkedDevelopment.name && task.linkedDevelopment.id) || (task.linkedContact && task.linkedContact.name && task.linkedContact.id)"
              class="task-view-widget-content-details">
            <div v-if="Object.values(task.dates).some((value) => value !== null)"
                 class="task-view-widget-content-details-row">
              <img :src="require('@/assets/images/calendar_20_20.svg')"/>
              <div :class="task.access.users[user._id] ? 'clickable-resource' : ''" @click="openResourceParent" style="display: flex; flex-direction: column">
                <p v-if="task.access.users[user._id]  && task.parentTask" class="content-semibold">{{ task.parentTask.content }}</p>
                <p class="content-normal">{{ formatDate(task.dates) }}</p>
              </div>
            </div>
            <div v-if="task.linkedUsers && task.linkedUsers.length > 0" style="width: 100%; height: 100%; display: flex; flex-direction: column; gap: 16px">
              <div v-for="user of task.linkedUsers" style="cursor: pointer;"
                   class="task-view-widget-content-details-row" :key="user.id">
                <img :src="require('@/assets/images/users_16_16.svg')"/>
                <p class="content-normal">{{ user.name }}</p>
              </div>
            </div>
            <div style="cursor: pointer;" @click="emitOpenSidepanel('project')" v-if="task.linkedProject && task.linkedProject.name && task.linkedProject.id"
                 class="task-view-widget-content-details-row">
              <img :src="require('@/assets/images/projects.svg')"/>
              <p class="content-normal">{{ task.linkedProject.name }}</p>
            </div>
            <div style="cursor: pointer;" @click="emitOpenSidepanel('development')" v-if="task.linkedDevelopment && task.linkedDevelopment.name && task.linkedDevelopment.id"
                  class="task-view-widget-content-details-row">
              <img :src="require('@/assets/images/PropertyDeveloper.svg')"/>
              <p class="content-normal">{{ task.linkedDevelopment.name }}</p>
            </div>
            <div style="cursor: pointer;" @click="emitOpenSidepanel('contact')" v-if="task.linkedContact && task.linkedContact.name && task.linkedContact.id"
                 class="task-view-widget-content-details-row">
              <img :src="require('@/assets/images/clients.svg')"/>
              <p class="content-normal">{{ task.linkedContact.name }}</p>
            </div>
            <div v-if="task.resources && task.resources.length > 0" style="width: 100%; height: 100%; display: flex; flex-direction: column; gap: 16px">
              <div v-for="resource of task.resources" style="cursor: pointer;" @click="emitOpenTask(resource.taskId)"
                   class="task-view-widget-content-details-row" :key="resource.name">
                <img :src="require('@/assets/images/pencil_20_20.svg')"/>
                <div style="display: flex; flex-direction: column">
                  <p class="content-normal">{{ resource.name }}</p>
                  <p v-if="resource.description" class="content-small" style="color: #75787a">{{ resource.description }}</p>
                </div>
              </div>
            </div>
            <div
                class="task-view-widget-comment-row"
            >
              <img src="@/assets/images/chat-no-bubble.svg" style="width: 20px; height: 20px" alt="">
              <div
                  @click="openEditComment"
                  v-if="!task.comment && !editComment"
              >
                <p class="link"> + {{ $t("addComment").charAt(0).toUpperCase() + $t("addComment").slice(1) }} </p>
              </div>
              <textarea
                  style="white-space: pre-wrap"
                  id="textarea"
                  class="task-textarea content-normal"
                  rows="5"
                  v-if="editComment"
                  ref="commentbox"
                  v-model="task.comment"
                  @focusout="closeEditComment"
              />
              <div
                  v-if="task.comment && !editComment"
                  @click="openEditComment"
                  style="display: flex; flex-direction: column;">
                <div class="content-normal" style="white-space: pre-wrap; width: 290px; overflow-wrap: break-word;">{{task.comment}}</div>
              </div>
            </div>

          </div>
        </div>
        <div class="task-view-widget-divider" style="height: 1px; width: 100%; background: #E6E8EC"/>
        <div class="task-view-widget-footer">
          <p class="content-normal" v-if="(task.category === 'task' || task.category === 'event') && task.isPrivate">{{ $t("Private") + " " + $t("Entry").toLowerCase() }}</p>
          <div class="task-view-widget-footer-content">
            <img height="20" width="20" :src="require('@/assets/images/users.svg')" alt=""/>
            <p class="content-normal" @click="(event) => openBrokerDetailPanel(event, assignedTo._id)" style="cursor:pointer">{{ assignedTo.name }}</p>
          </div>
        </div>
         <BrokerInfoPanel
          v-if="brokerDetailPanelOpened"
          :item="clickedBroker"
          @closed="closeBrokerDetailPanel"
          :position="brokerPanelPosition"
          />
      </div>
    </template>
    <div v-if="loading" class="task-view-widget-loading-container">
      <div class="loader"></div>
    </div>
  </vue-draggable-resizable>
  
</template>

<script>
import VueDraggableResizable from "vue-draggable-resizable";
import taskTypes from "@/components/CalendarView/taskTypes";
import moment from "moment";
import axios from "axios";
import BrokerInfoPanel from "@/components/common/BrokerInfoPanel";
import {mapGetters} from "vuex";


export default {
  name: "TaskViewWidget",
  components: {
    VueDraggableResizable,
    BrokerInfoPanel
  },
  props: ["task"],
  data() {
    return {
      taskType: null,
      loading: true,
      brokerDetailPanelOpened: false,
      clickedBroker: null,
      brokerPanelPosition: null,
      editComment: false,
    }
  },
  mounted() {
    if (this.task) {
        this.taskType = taskTypes.find((type) => type.value === this.task.type);
    }
    this.loading = false;
  },
  watch: {
    task(value) {
      this.taskType = taskTypes.find((type) => type.value === value.type);
    }
  },
  computed: {
    ...mapGetters(["user"]),
    assignedTo() {
      //TODO access schema might need custom indexing object.
      // Verify when sharing tasks with other users is added.
      for (const userId in this.task.access.users) {
        if (this.task.access.users[userId].ownershipType === "owner") {
          return {_id: userId, ...this.task.access.users[userId]}
        }
      }
      return null;
    }
  },
  methods: {
    openBrokerDetailPanel(event, person) {
        let position = {
        left: null,
        top: null,
      }
      position.left = event.pageX + 'px'
      position.top = event.clientY + 'px'
      this.brokerPanelPosition = position;
      this.clickedBroker = person;
      this.brokerDetailPanelOpened = true;
    },
    closeBrokerDetailPanel() {
      this.brokerDetailPanelOpened = false;

    },
    emitOpenSidepanel(sidepanelType) {
      if(this.editComment) {
        return
      }
      switch (sidepanelType) {
        case "project":
          this.$emit("openProjectSidepanel", this.task.linkedProject.id)
          break;
        case "contact":
          this.$emit("openContactSidepanel", this.task.linkedContact.id)
          break;
      }
    },
    emitOpenTask(taskId) {
      this.$emit("openTask", taskId)
    },
    openResourceParent() {
      if (this.task.parentTask && this.task.parentTask.taskId && this.user._id === this.task.creator.userId) {
        this.emitOpenTask(this.task.parentTask.taskId)
      }
    },
    getTaskIconName() {
      if (this.task.category === 'resource') {
        return "pencil_20_20.svg"
      }
      switch (this.task.icon) {
        case 'task':
          return "flag_20_20.svg";
        case 'call':
          return "call.svg";
        case 'email':
          return "email_24_24.svg";
        case 'invoice':
          return "invoice.svg";
      }
    },
    openDeleteModal() {
      this.$emit("openDeleteModal");
    },
    changeTaskCompleteStatus() {
      this.task.isCompleted = !this.task.isCompleted;
      this.task.completedAt = this.task.isCompleted ? moment().format() : "";
      this.saveUpdatedTask();
      this.$emit("taskUpdated", this.task);
    },
    async saveUpdatedTask() {
      await axios.patch(`/api/task/${this.task._id}`, this.task);
    },
    closeWidget(event) {
      // this.saveUpdatedTask()
      if (this.editComment) return
      if (event.target.classList[0].includes("overlay")) return
      this.$emit("closeWidget")
    },
    addParticipants() {
      this.$emit('addParticipants', this.task)
    },
    editTask() {
      this.$emit("editTask", this.task);
    },
    openEditComment() {
      this.editComment = true
      this.$nextTick(() => {
        this.$refs.commentbox.focus();
      });
    },
    closeEditComment() {
      const closeEditComment = setInterval( () => {
        this.editComment = false
        clearInterval(closeEditComment);
      }, 70);
      this.saveUpdatedTask()
    },
    formatDate(date) {
      if (!date.startTime && !date.endTime && !date.startDate && !date.endDate) {
        return;
      }
      if (date.startTime && date.endTime && date.startDate && date.endDate) {
        if (this.task.category === 'task') {
          const formatedEndDate = moment(date.endDate).format('dddd, DD.MM');
          const times = date.endTime;
          return formatedEndDate + " • " + times;
        } else {
          const startDate = date.startDate ? moment(date.startDate).endOf('day') : '';
          if (moment(date.endDate).isAfter(startDate)) {
            const formatedStartDate = moment(date.startDate).format('dddd, DD.MM');
            const formatedEndDate = moment(date.endDate).format('dddd, DD.MM');
            return formatedStartDate + ", " + date.startTime + " - " + formatedEndDate + ", " + date.endTime
          } else {
            const formatedEndDate = moment(date.endDate).format('dddd, DD.MM');
            const times = date.startTime + " - " + date.endTime;
            return formatedEndDate + " • " + times;
          }
        }
      }
      if (date.endTime && date.endDate) {
        return moment(date.endDate).format('dddd, DD.MM • HH:mm')
      }
      if (date.endDate) {
        return moment(date.endDate).format('dddd, DD.MM');
      }
    },
  }
}
</script>

<style scoped lang="scss">
.widget-container {
  position: fixed;
  background: white;
  border-radius: 8px;
  top: calc(50% - 220px);
  right: calc(50% - 240px);
  min-width: 416px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.11), 2px 1px 9px rgba(0, 0, 0, 0.11), 0 0 0 rgba(0, 0, 0, 0.1);
}

.task-view-widget-header {
  background-color: #F4F5F7;
  height: 40px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E6E8EC;
  border-radius: 8px 8px 0 0;
  padding: 12px 16px;
  gap: 32px;
  align-items: center;
}

.drag-area {
  cursor: grab;
}

.drag-area:active {
  cursor: grabbing;
}

.task-view-widget-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.task-view-widget-divider {
  height: 1px;
  width: 100%;
  background: #E6E8EC;
  margin: 16px 0;
}

.task-view-widget-body {
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.task-view-widget-label {
  display: block;
  padding: 4px 8px;
  height: 24px;
  font-weight: bold;
  color: white;
  width: fit-content;
  border-radius: 4px;
}

.task-view-widget-content-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.task-view-widget-loading-container {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-view-widget-content-details-row {
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  min-height: 20px;
}

.task-view-widget-content-details-row img {
  width: 20px;
  height: 20px;
}

.task-view-widget-footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.task-view-widget-footer p {
  white-space: nowrap;
}

.task-view-widget-footer-content {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 12px;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.task-view-widget-content-title {
  display: flex;
  gap: 12px;
  flex-wrap: nowrap;
}

.task-view-widget-content-header {
  font-size: 18px;
  line-height: 20px;
  font-family: Inter, sans-serif;
  font-weight: bold;
}
.open-house-content {
  gap: 0px !important
}
.open-house-gap {
  margin-top: 8px;
  margin-bottom: 24px;
}

.task-textarea#textarea{
  width: 70%;
  p {
  font-size: 14px;
  }
}
.task-view-widget-comment-row {
  display: flex;
  flex-direction: row;
  gap: 12px;
  &:hover {
   cursor: pointer;
  }
}
.task-view-comment {
  line-height: 20px;
}

.task-comment-header {

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #75787A;
  }
}

.clickable-resource {
  cursor: pointer;
}

</style>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "calendar-types content-small"
  }, [_c('div', {
    class: !_vm.type ? 'selected-calendar-status' : 'calendar-status',
    on: {
      "click": function ($event) {
        return _vm.$emit('selectStatus', '');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("All")) + " ")]), _c('div', {
    class: _vm.type === 'event' ? 'selected-calendar-status' : 'calendar-status',
    on: {
      "click": function ($event) {
        return _vm.$emit('selectStatus', 'event');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Events")) + " ")]), _c('div', {
    class: _vm.type === 'task' ? 'selected-calendar-status' : 'calendar-status',
    on: {
      "click": function ($event) {
        return _vm.$emit('selectStatus', 'task');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Tasks")) + " ")]), _c('div', {
    class: _vm.type === 'open_house' ? 'selected-calendar-status purple-color' : 'calendar-status',
    on: {
      "click": function ($event) {
        return _vm.$emit('selectStatus', 'open_house');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("OpenHouse")) + " ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "calendar-sidepanel"
  }, [_c('div', {
    staticClass: "calendar-sidepanel-header"
  }, [_c('h5', {
    staticClass: "calendar-sidepanel-header-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("TasksAndEvents")) + " ")]), _c('BaseButton', {
    attrs: {
      "icon-left": 'add_16_16.svg',
      "size": "small",
      "label": _vm.$t('Add')
    },
    on: {
      "click": _vm.emitOpenTaskAdd
    }
  })], 1), _c('div', {
    staticStyle: {
      "padding-left": "24px",
      "padding-right": "24px"
    }
  }, [_c('CalendarTypeFilter', {
    attrs: {
      "type": _vm.type
    },
    on: {
      "selectStatus": _vm.filterByType
    }
  })], 1), _vm.tasksLoaded && _vm.tasks.length > 0 && !_vm.sortedEmpty ? _c('div', {
    key: 'calendar-sidepanel-' + _vm.htmlKeyUpdater,
    staticClass: "calendar-sidepanel-content"
  }, [_vm.overdueTasks.length > 0 ? _c('div', {
    staticClass: "calendar-sidepanel-content-section"
  }, [_c('div', {
    staticClass: "label12"
  }, [_vm._v(_vm._s(_vm.$t("Overdue")))]), _vm._l(_vm.overdueTasks, function (task) {
    return _c('div', {
      key: task._id
    }, [_c('Task', {
      attrs: {
        "task": task,
        "end-date-passed": true
      },
      on: {
        "addParticipants": _vm.emitAddParticipants,
        "openTaskViewWidget": _vm.emitOpenTaskViewWidget
      }
    })], 1);
  })], 2) : _vm._e(), _vm.noDueDateTasks.length > 0 ? _c('div', {
    staticClass: "calendar-sidepanel-content-section"
  }, [_c('div', {
    staticClass: "label12"
  }, [_vm._v(_vm._s(_vm.$t("NoDueDate")))]), _vm._l(_vm.noDueDateTasks, function (task) {
    return _c('div', {
      key: task._id
    }, [_c('Task', {
      attrs: {
        "task": task
      },
      on: {
        "addParticipants": _vm.emitAddParticipants,
        "openTaskViewWidget": _vm.emitOpenTaskViewWidget
      }
    })], 1);
  })], 2) : _vm._e(), _vm.todayTasks.length > 0 ? _c('div', {
    staticClass: "calendar-sidepanel-content-section"
  }, [_c('div', {
    staticClass: "label12"
  }, [_vm._v(_vm._s(_vm.$t("Today")))]), _vm._l(_vm.todayTasks, function (task) {
    return _c('div', {
      key: task._id
    }, [_c('Task', {
      attrs: {
        "task": task
      },
      on: {
        "addParticipants": _vm.emitAddParticipants,
        "openTaskViewWidget": _vm.emitOpenTaskViewWidget
      }
    })], 1);
  })], 2) : _vm._e(), _vm.tomorrowTasks.length > 0 ? _c('div', {
    staticClass: "calendar-sidepanel-content-section"
  }, [_c('div', {
    staticClass: "label12"
  }, [_vm._v(_vm._s(_vm.$t("Tomorrow")))]), _vm._l(_vm.tomorrowTasks, function (task) {
    return _c('div', {
      key: task._id
    }, [_c('Task', {
      attrs: {
        "task": task
      },
      on: {
        "addParticipants": _vm.emitAddParticipants,
        "openTaskViewWidget": _vm.emitOpenTaskViewWidget
      }
    })], 1);
  })], 2) : _vm._e(), _vm.upcomingTasks.length > 0 ? _c('div', {
    staticClass: "calendar-sidepanel-content-section"
  }, [_c('div', {
    staticClass: "label12"
  }, [_vm._v(_vm._s(_vm.$t("DateNext")))]), _vm._l(_vm.upcomingTasks, function (task) {
    return _c('div', {
      key: task._id
    }, [_c('Task', {
      attrs: {
        "task": task
      },
      on: {
        "addParticipants": _vm.emitAddParticipants,
        "openTaskViewWidget": _vm.emitOpenTaskViewWidget
      }
    })], 1);
  })], 2) : _vm._e()]) : _vm._e(), _vm.tasksLoaded && _vm.tasks.length < 1 && !_vm.errorGettingTasks && !_vm.errorRetrievingTasks && !_vm.sortedEmpty ? _c('div', {
    staticClass: "calendar-sidepanel-content-empty"
  }, [_c('div', {
    staticClass: "subheading"
  }, [_vm._v(" " + _vm._s(_vm.$t("NoTasksEvents")) + " ")]), _c('div', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("Click")) + " "), _c('span', {
    staticStyle: {
      "font-weight": "bold",
      "padding": "0 4px"
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t("Add")) + " ")]), _vm._v(" " + _vm._s(_vm.$t("ToAddNewTaskOrEvent")) + " ")])]) : _vm._e(), _vm.sortedEmpty ? _c('div', {
    staticClass: "calendar-sidepanel-content-empty"
  }, [_c('div', {
    staticClass: "subheading"
  }, [_vm._v(" " + _vm._s(_vm.getEmptyMessage(_vm.type)) + " ")]), _c('div', {
    staticClass: "content-small"
  }, [_vm._v(" " + _vm._s(_vm.$t("Click")) + " "), _c('span', {
    staticStyle: {
      "font-weight": "bold",
      "padding": "0 4px"
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t("Add")) + " ")]), _vm._v(" " + _vm._s(_vm.getEmptyMessageType(_vm.type)) + " ")])]) : _vm._e(), _vm.tasksLoaded && _vm.tasks.length < 1 && (_vm.errorGettingTasks || _vm.errorRetrievingTasks) ? _c('div', {
    staticClass: "calendar-sidepanel-content-empty"
  }, [_c('div', {
    staticClass: "subheading"
  }, [_vm._v(" " + _vm._s(_vm.$t("SomethingWentWrong")) + " ")]), _c('BaseButton', {
    attrs: {
      "label": _vm.$t('TryAgain')
    },
    on: {
      "click": _vm.emitTasksReload
    }
  })], 1) : _vm._e(), !_vm.tasksLoaded ? _c('div', {
    staticClass: "calendar-sidepanel-content-loading"
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="calendar-types content-small">
    <div
      :class="!type ? 'selected-calendar-status' : 'calendar-status'"
      @click="$emit('selectStatus', '')"
    >
      {{ $t("All") }}
    </div>
    <div
      :class="type === 'event' ? 'selected-calendar-status' : 'calendar-status'"
      @click="$emit('selectStatus', 'event')"
    >
      {{ $t("Events") }}
    </div>
    <div
      :class="type === 'task' ? 'selected-calendar-status' : 'calendar-status'"
      @click="$emit('selectStatus', 'task')"
    >
      {{ $t("Tasks") }}
    </div>
    <div
      :class="
        type === 'open_house'
          ? 'selected-calendar-status purple-color'
          : 'calendar-status'
      "
      @click="$emit('selectStatus', 'open_house')"
    >
     {{ $t("OpenHouse") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarTypeFilter",
  props: ["type"],
};
</script>

<style lang="scss" scoped>
@import "src/global_variables";

.calendar-types {
  background: $grey500;
  border-radius: 8px;
  display: flex;
  gap: 1px;
  white-space: nowrap;
}

.selected-calendar-status {
  padding: 4px 8px;
  font-weight: 600;
  border: 2px solid #000000;
  flex-basis: 100%;
  text-align: center;
  background: #fff;
}

.selected-calendar-status:first-child {
  border-radius: 6px 0px 0px 6px;
}

.selected-calendar-status:last-child {
  border-radius: 0px 6px 6px 0px;
}

.purple-color {
  color: #7200e2;
  border: 2px solid #7200e2;
}

.calendar-status {
  background: #fff;
  padding: 5px 8px;
  cursor: pointer;
  flex-basis: 100%;
  text-align: center;
}

.calendar-status:first-child {
  border-radius: 6px 0px 0px 6px;
  border: 1px solid $grey500;
  border-right: 0px;
}

.calendar-status:last-child {
  border-radius: 0px 6px 6px 0px;
  border: 1px solid $grey500;
  border-left: 0px;
}
</style>
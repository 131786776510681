var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Header', {
    attrs: {
      "taskTypes": _vm.taskTypes,
      "update-element": _vm.headerRefresh
    }
  }), _c('Content', {
    attrs: {
      "error-retrieving-tasks": _vm.errorRetrievingTasks,
      "selected-contact-id": _vm.selectedContactId,
      "selected-project-id": _vm.selectedProjectId,
      "shared-resource-tasks": _vm.sharedResourceTasks,
      "calendar-tasks": _vm.calendarTasks,
      "sidepanel-tasks": _vm.sidepanelTasks
    },
    on: {
      "openProjectSidepanel": _vm.openProjectSidepanel,
      "openContactSidepanel": _vm.openContactSidepanel,
      "getTasks": _vm.getTasks,
      "viewTaskUpdated": _vm.viewTaskUpdated,
      "monthChanged": _vm.handleMonthChange
    }
  }), _vm.selectedContactId ? _c('ClientSidepanel', {
    on: {
      "panelClosed": function ($event) {
        _vm.selectedContactId = null;
      }
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
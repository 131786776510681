var render = function render(){
  var _vm$user$access, _vm$user$access$featu, _vm$user$access$featu2, _vm$user$access$featu3, _vm$user$access$featu4, _vm$user$access$featu5;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "calendar-wrap"
  }, [_c('div', {
    staticClass: "calendar-main"
  }, [_c('v-sheet', {
    staticClass: "calendar-calendar-header",
    style: _vm.calendarContentStyle
  }, [_c('div', {
    staticStyle: {
      "margin-left": "16px",
      "display": "flex",
      "flex-wrap": "nowrap"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', {
    staticStyle: {
      "cursor": "pointer",
      "display": "flex",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.calendarChoice('previous');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/chevron-left.svg")
    }
  })])]), _vm.$refs.calendar ? _c('h5', {
    staticStyle: {
      "margin": "14px 10px 10px 10px",
      "cursor": "pointer"
    }
  }, [_vm._v(" " + _vm._s(_vm.$refs.calendar.title) + " ")]) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('div', {
    staticStyle: {
      "cursor": "pointer",
      "display": "flex",
      "align-items": "center"
    },
    on: {
      "click": function ($event) {
        return _vm.calendarChoice('next');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/chevron-right.svg")
    }
  })])])]), _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "margin": "0 16px"
    }
  }, [_c('button', {
    staticClass: "outlined-small-button",
    on: {
      "click": _vm.setCalendarToToday
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Today")) + " ")])]), _c('v-spacer'), _c('div', {
    staticClass: "calendar-togglers"
  }, [_vm.showWeekends !== undefined ? _c('div', {
    staticClass: "weekend-toggler"
  }, [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('showWeekends')
    },
    model: {
      value: _vm.showWeekends,
      callback: function ($$v) {
        _vm.showWeekends = $$v;
      },
      expression: "showWeekends"
    }
  })], 1) : _vm._e(), _c('BaseRadioGroup', {
    attrs: {
      "items": _vm.calendarViewItems,
      "display-key": "name",
      "display-value": "value"
    },
    model: {
      value: _vm.type,
      callback: function ($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }), (_vm$user$access = _vm.user.access) !== null && _vm$user$access !== void 0 && (_vm$user$access$featu = _vm$user$access.features) !== null && _vm$user$access$featu !== void 0 && (_vm$user$access$featu2 = _vm$user$access$featu.modules) !== null && _vm$user$access$featu2 !== void 0 && (_vm$user$access$featu3 = _vm$user$access$featu2.calendar) !== null && _vm$user$access$featu3 !== void 0 && (_vm$user$access$featu4 = _vm$user$access$featu3.features) !== null && _vm$user$access$featu4 !== void 0 && (_vm$user$access$featu5 = _vm$user$access$featu4.sharing) !== null && _vm$user$access$featu5 !== void 0 && _vm$user$access$featu5.enabled ? _c('div', {
    staticClass: "calendar-details-toggler",
    class: _vm.isCalendars ? 'calendars-show' : 'calendars-hide',
    on: {
      "click": _vm.toggleIsCalendars
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/users_16_16.svg"),
      "alt": ""
    }
  }), _c('p', [_vm._v(" " + _vm._s(_vm.$t("UsersAndResources")) + " ")])]) : _vm._e()], 1)], 1), _c('v-sheet', {
    staticClass: "calendar-calendar-content"
  }, [_c('v-calendar', {
    ref: "calendar",
    staticClass: "calendar",
    attrs: {
      "interval-format": _vm.intervalFormat,
      "locale": _vm.language,
      "weekdays": _vm.weekday,
      "events": _vm.events,
      "type": _vm.type
    },
    on: {
      "click:time": _vm.openTaskAddWidget,
      "click:event": _vm.openTaskViewWidget,
      "click:day": _vm.openTaskAddWidgetMonth,
      "click:date": _vm.viewDay,
      "click:more": _vm.openMoreTasksWidget,
      "mousedown:event": _vm.startDragEvent,
      "mouseup:time": _vm.endDragEvent,
      "mousedown:time": _vm.startTime,
      "mousemove:time": _vm.mouseMove,
      "input": _vm.updateCurrentMonth
    },
    nativeOn: {
      "mouseleave": function ($event) {
        return _vm.cancelDragEvent.apply(null, arguments);
      }
    },
    scopedSlots: _vm._u([{
      key: "event",
      fn: function (_ref) {
        var event = _ref.event;
        return [_c('div', {
          staticClass: "v-event-side",
          style: {
            background: event.sideColor
          }
        }), _c('div', {
          staticClass: "v-event-draggable"
        }, [_c('div', {
          staticClass: "content-small event-name"
        }, [_vm._v(" " + _vm._s(event.name) + " "), event.task && event.task.dates ? _c('span', {
          staticClass: "content-small event-date"
        }, [_vm._v(_vm._s(event.task.dates.startTime) + " - " + _vm._s(event.task.dates.endTime))]) : _vm._e()])])];
      }
    }, {
      key: "day-body",
      fn: function (_ref2) {
        var date = _ref2.date,
          week = _ref2.week;
        return _vm._l(week, function (day) {
          return _c('div', {
            key: day.date
          }, [date === day.date && day.present === true ? _c('div', {
            staticClass: "v-current-time",
            style: {
              top: _vm.nowY
            }
          }) : _vm._e()]);
        });
      }
    }]),
    model: {
      value: _vm.focus,
      callback: function ($$v) {
        _vm.focus = $$v;
      },
      expression: "focus"
    }
  })], 1)], 1), _vm.isCalendars ? _c('v-sheet', {
    staticClass: "calendar-view-panel"
  }, [_c('div', {
    staticClass: "calendar-sidepanel-header"
  }, [_c('h5', {
    staticClass: "calendar-sidepanel-header-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('UsersAndResources')) + " ")])]), _c('div', {
    staticClass: "search-wrapper"
  }, [_c('TaskLinkedToSearch', {
    attrs: {
      "custom-placeholder": _vm.$t('AddUser'),
      "default-search": true,
      "items": _vm.userColleagues
    },
    model: {
      value: _vm.selectedColleagueId,
      callback: function ($$v) {
        _vm.selectedColleagueId = $$v;
      },
      expression: "selectedColleagueId"
    }
  })], 1), _c('div', {
    staticClass: "toggle-wrapper"
  }, [_vm.showMyCalendar !== undefined ? _c('div', [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('MyCalendar'),
      "toggle-colour": "black"
    },
    model: {
      value: _vm.showMyCalendar,
      callback: function ($$v) {
        _vm.showMyCalendar = $$v;
      },
      expression: "showMyCalendar"
    }
  })], 1) : _vm._e(), _vm.showResources !== undefined ? _c('div', [_c('BaseToggle', {
    attrs: {
      "label": _vm.$t('showResources'),
      "toggle-colour": "turquoise"
    },
    model: {
      value: _vm.showResources,
      callback: function ($$v) {
        _vm.showResources = $$v;
      },
      expression: "showResources"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "user-groups"
  }, [_vm.selectedColleaguesCalendars.length > 0 ? _c('div', {
    key: 'otherSection'
  }, [_c('div', {
    staticClass: "group-calendars"
  }, [_c('div', {
    staticClass: "group-calendar-header",
    on: {
      "click": function ($event) {
        return _vm.openCalendar('otherSection');
      }
    }
  }, [_c('div', {
    staticClass: "group-calendar-title content-small semi-bold"
  }, [_vm._v(_vm._s(_vm.$t('Other')))]), _c('img', {
    attrs: {
      "src": _vm.getIconSrc('otherSection'),
      "alt": ""
    }
  })]), _vm.openAccordions.includes('otherSection') ? _c('div', {
    staticClass: "group-calendar-wrapper"
  }, _vm._l(_vm.selectedColleaguesCalendars, function (colleague, index) {
    return _c('div', {
      key: colleague.id
    }, [_vm.refreshKey ? _c('div', {
      staticClass: "group-calendar-item-wrapper"
    }, [_c('div', {
      staticClass: "group-calendar-item-left"
    }, [_c('BaseToggle', {
      attrs: {
        "value": _vm.checkedUsers[colleague.id],
        "label": colleague.name,
        "toggle-colour": "black"
      },
      on: {
        "change": function ($event) {
          return _vm.handleToggleChange(colleague.id, $event);
        }
      }
    })], 1), _c('img', {
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "src": require('@/assets/images/close_24_24.svg'),
        "width": "20",
        "height": "20",
        "alt": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeColleague(index);
        }
      }
    })]) : _vm._e()]);
  }), 0) : _vm._e()])]) : _vm._e(), _vm._l(_vm.userGroups, function (item, index) {
    return _c('div', {
      key: item.id
    }, [_c('div', {
      staticClass: "group-calendars"
    }, [_c('div', {
      staticClass: "group-calendar-header",
      on: {
        "click": function ($event) {
          return _vm.openCalendar(index);
        }
      }
    }, [_c('div', {
      staticClass: "group-calendar-title content-small semi-bold"
    }, [_vm._v(_vm._s(item.name))]), _c('img', {
      attrs: {
        "src": _vm.getIconSrc(index),
        "alt": ""
      }
    })]), _vm.openAccordions.includes(index) ? _c('div', {
      staticClass: "group-calendar-wrapper"
    }, _vm._l(item.groupUsers, function (user) {
      return _c('div', {
        key: user.id,
        staticClass: "group-calendar"
      }, [_vm.refreshKey ? _c('div', {
        staticClass: "group-calendar-item-wrapper"
      }, [_c('div', {
        staticClass: "group-calendar-item-left"
      }, [_c('BaseToggle', {
        attrs: {
          "value": _vm.checkedUsers[user.id],
          "label": user.name,
          "toggle-colour": "black"
        },
        on: {
          "change": function ($event) {
            return _vm.handleToggleChange(user.id, $event);
          }
        }
      })], 1)]) : _vm._e()]);
    }), 0) : _vm._e()])]);
  })], 2)]) : _vm._e(), _vm.tasksViewMore ? _c('TasksViewMoreWidget', {
    attrs: {
      "widget-information": _vm.tasksViewMore
    },
    on: {
      "openTaskViewWidget": _vm.openTaskViewWidgetFromViewMore,
      "closeWidget": _vm.closeMoreTasksWidget
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    v-click-outside="emitCloseWidget"
    ref="tasksViewMore"
    :style="style"
    class="tasks-view-more-container"
  >
    <div
      @click="emitOpenTaskViewWidget(task)"
      v-for="task of allDayTasks"
      :key="task._id"
      class="task-view-more-task"
      :style="{
        backgroundColor: require('@/components/CalendarView/taskTypes').find(
          (type) => type.value === task.type
        ).color,
      }"
    >
      <p
        v-if="task.dates.startTime"
        style="font-weight: bolder; white-space: nowrap"
        class="content-small"
      >
        {{ formatTime(task.dates.startTime) }}
      </p>
      <p
        style="
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        "
        class="content-small"
      >
        {{ task.type === "open_house" ? $t("OpenHouse") : task.content }}
      </p>
    </div>
    <div
      @click="emitOpenTaskViewWidget(task)"
      v-for="task of tasks"
      :key="task._id"
      class="task-view-more-task"
      :style="{
        backgroundColor: require('@/components/CalendarView/taskTypes').find(
          (type) => type.value === task.type
        ).color,
      }"
    >
      <p
        v-if="task.dates.startTime"
        style="font-weight: bolder; white-space: nowrap"
        class="content-small"
      >
        {{ formatTime(task.dates.startTime) }}
      </p>
      <p
        style="
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        "
        class="content-small"
      >
        {{ task.type === "open_house" ? $t("OpenHouse") : task.content }}
      </p>
    </div>
  </div>
</template>

<script>
//import taskTypes from "@/components/CalendarView/taskTypes";
import moment from "moment";

export default {
  name: "TasksViewMoreWidget",
  props: ["widgetInformation"],
  data() {
    return {
      style: {},
      tasks: [],
      allDayTasks: [],
      updateElement: 0,
    };
  },
  created() {
    const locationOffsetY = 126;
    this.sortTasks(this.widgetInformation.tasks);
    this.allDayTasks = this.widgetInformation.allDayTasks;
    this.style = {
      top:
        this.widgetInformation.position.top +
        window.scrollY -
        locationOffsetY +
        "px",
      left: this.widgetInformation.position.left + "px",
    };
  },
  mounted() {
    const rect = this.$refs.tasksViewMore.getBoundingClientRect();
    const locationOffsetY = 126;
    if (
      rect.bottom >
      (window.innerHeight || document.documentElement.clientHeight)
    ) {
      this.$refs.tasksViewMore.style.top = `${
        Math.floor(window.innerHeight) -
        Math.floor(this.$refs.tasksViewMore.clientHeight)
      }px`;
    } else {
      this.$refs.tasksViewMore.style.top =
        this.widgetInformation.position.top +
        window.scrollY -
        locationOffsetY +
        "px";
    }
  },
  methods: {
    emitCloseWidget() {
      this.$emit("closeWidget");
    },
    sortTasks(tasks) {
      this.tasks = tasks.sort(
        (a, b) =>
          moment(a.dates.endDate).valueOf() - moment(b.dates.endDate).valueOf()
      );
    },
    emitOpenTaskViewWidget(task) {
      this.$emit("openTaskViewWidget", task);
      this.emitCloseWidget();
    },
    formatTime(time) {
      if (this.$i18n.locale === "en") {
        return moment(time, ["HH:mm"]).format("hh:mm A");
      } else {
        return time;
      }
    },
  },
};
</script>

<style scoped>
.tasks-view-more-container {
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: white;
  width: 160px;
  border-radius: 8px;
  padding: 12px 8px;
  gap: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.11), 2px 1px 9px rgba(0, 0, 0, 0.11),
    0 0 0 rgba(0, 0, 0, 0.1);
}

.task-view-more-task {
  display: flex;
  gap: 4px;
  flex-wrap: nowrap;
  color: white;
  border-radius: 6px;
  padding: 4px 6px;
  height: 24px;
  cursor: pointer;
}

.task-view-more-task p {
  color: white;
}
</style>

var render = function render(){
  var _vm$user, _vm$user$access, _vm$user$access$featu, _vm$user$access$featu2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "calendar-header"
  }, [_c('div', {
    staticClass: "calendar-header-title",
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "justify-content": "space-between"
    }
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Calendar")))]), _vm.user.nylas ? _c('BaseButton', {
    attrs: {
      "icons-style": {
        width: '16px',
        height: '16px'
      },
      "label": _vm.$t('disconnectExternalCalendar'),
      "icon-left": 'log-out.svg'
    },
    on: {
      "click": _vm.logoutNylas
    }
  }) : _vm._e(), (_vm$user = _vm.user) !== null && _vm$user !== void 0 && (_vm$user$access = _vm$user.access) !== null && _vm$user$access !== void 0 && (_vm$user$access$featu = _vm$user$access.features) !== null && _vm$user$access$featu !== void 0 && (_vm$user$access$featu2 = _vm$user$access$featu.nylas) !== null && _vm$user$access$featu2 !== void 0 && _vm$user$access$featu2.enabled && !_vm.user.nylas ? _c('BaseButton', {
    attrs: {
      "icons-style": {
        width: '16px',
        height: '16px'
      },
      "label": _vm.$t('connectExternalCalendar'),
      "icon-left": 'add-link.svg'
    },
    on: {
      "click": _vm.loginNylas
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="calendar-header">
    <div class="calendar-header-title" style="display: flex; flex-wrap: nowrap; justify-content: space-between">
      <h3>{{ $t("Calendar") }}</h3>
      <BaseButton 
        v-if="user.nylas"
        @click="logoutNylas" 
        :icons-style="{width: '16px', height: '16px'}" 
        :label="$t('disconnectExternalCalendar')" 
        :icon-left="'log-out.svg'"
      />
      <BaseButton 
        v-if="user?.access?.features?.nylas?.enabled && !user.nylas" 
        @click="loginNylas" 
        :icons-style="{width: '16px', height: '16px'}" 
        :label="$t('connectExternalCalendar')" 
        :icon-left="'add-link.svg'"
      />
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import BaseButton from "@/components/common/BaseButton";
import axios from "axios";

export default {
  name: "Header",
  components: {BaseButton},
  props: ["taskTypes", "updateElement"],
  data() {
    return {
      elementUpdateKey: 0,
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {},
  methods: {
    ...mapActions(['setNylasAccount']),
    async loginNylas() {
      try {
        const redirect_uri =  window.location.origin + "/nylas/callback"
        const {data} = await axios.get(`/api/nylas/endpoint`,{params:{callbackUri: redirect_uri, }});
        window.location.href = data.url
      } catch (error) {
        console.log('error: ', error)
      }
    },
    async logoutNylas() {
      try {
        await axios.post(`/api/nylas/logout/${this.user._id}`);
        this.setNylasAccount(null);
        location.reload();
      } catch (e) {
        this.toastError(this.$t("ErrorDeleting"));
      }
    },
  }
}
</script>

<style scoped>
.calendar-header {
  margin-left: 64px;
  background: #fff;
}

.calendar-header-title {
  height: 72px;
  padding: 16px 48px 24px 48px;
  border-bottom: 1px solid #F4F5F7;
}

.calendar-header-filters {
  height: 64px;
  padding: 22px 48px;
  display: flex;
  gap: 32px;
}

.calendar-header-filter {
  gap: 12px;
  display: flex;
  position: relative;
}

.calendar-header-filter-color {
  border-radius: 4px;
  height: 20px;
  width: 20px;
}

.calendar-header-filter-content {
  display: flex;
  gap: 4px;
}

.calendar-header-filter-dropdown {
  position: absolute;
  cursor: pointer;
  border: 1px solid #E6E8EC;
  border-radius: 8px;
  outline: none;
  background: white;
  top: 20px;
  width: 200px;
  z-index: 2;
}

.calendar-header-filter-dropdown-item {
  padding: 12px 16px;
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
}

.calendar-header-filter-dropdown-item:hover {
  background: #F4F5F7;
}

.clickable-filter {
  cursor: pointer;
}
</style>
<template>
  <div>
    <Header :taskTypes="taskTypes" :update-element="headerRefresh" />
    <Content
      @openProjectSidepanel="openProjectSidepanel"
      @openContactSidepanel="openContactSidepanel"
      @getTasks="getTasks"
      @viewTaskUpdated="viewTaskUpdated"
      @monthChanged="handleMonthChange"
      :error-retrieving-tasks="errorRetrievingTasks"
      :selected-contact-id="selectedContactId"
      :selected-project-id="selectedProjectId"
      :shared-resource-tasks="sharedResourceTasks"
      :calendar-tasks="calendarTasks"
      :sidepanel-tasks="sidepanelTasks"
    />
    <ClientSidepanel
      v-if="selectedContactId"
      @panelClosed="selectedContactId = null"
    />
  </div>
</template>

<script>
import Header from "@/components/CalendarView/Header";
import Content from "@/components/CalendarView/Content";
import taskTypes from "@/components/CalendarView/taskTypes";
import ClientSidepanel from "@/components/Kliendiregister/ClientSidepanel.vue";
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "CalendarView",
  components: {
    Header,
    Content,
    ClientSidepanel,
  },
  data() {
    return {
      selectedProjectId: null,
      selectedContactId: null,
      headerRefresh: 0,
      sidepanelTasks: null,
      calendarTasks: null,
      sharedResourceTasks: null,
      taskTypes: [],
      errorRetrievingTasks: false,
      currentMonth: null,
    };
  },
  created() {
    window.addEventListener("beforeunload", this.updateBackButton);
    this.taskTypes = taskTypes;
    this.getTasks();
    this.getSharedResources();
  },
  beforeDestroy() {
    this.updateBackButton();
  },
  computed: {
    ...mapGetters(["user", "showBackButton", "calendarShowResources"]),
  },

  watch: {
    calendarShowResources() {
      this.getCalendarTasks();
    },
    currentMonth(newMonth) {
      if (newMonth) {
        this.getCalendarTasks(newMonth);
      }
    },
  },
  methods: {
    ...mapActions(["setShowBackButton", "updateBackButton"]),
    async openProjectSidepanel(id) {
      this.selectedProjectId = id;
      await this.$router.push({
        name: "CalendarDealDetail",
        params: { id: id },
      });
    },
    async openContactSidepanel(id) {
      this.selectedContactId = id;
      await this.$router.push({
        name: "CalendarCustomerDetail",
        params: { id: id },
      });
    },
    async getTasks(updateViewName = null) {
      switch (updateViewName) {
        case "sidepanel":
          await this.getBrokerTasks();
          break;
        case "calendar":
          await this.getCalendarTasks();
          break;
        default:
          await this.getBrokerTasks();
          await this.getCalendarTasks();
      }
    },
    async getBrokerTasks() {
      this.errorRetrievingTasks = false;

      let endDate = moment().startOf('month').add(3, 'months').format('YYYY-MM-DD');

      const url = `/api/tasks/active/assignee/${this.user._id}?end=${endDate}`;

      await axios
        .get(url)
        .then((response) => {
          if (
            response &&
            response.status &&
            response.status === 200 &&
            response.data
          ) {
            this.sidepanelTasks = response.data.map(task => {
              return {
                ...task,
                dates: {
                  ...task.dates,
                  startTime: task.dates.startTime? moment(task.dates.startDate).format('HH:mm'): null,
                  endTime: task.dates.endTime? moment(task.dates.endDate).format('HH:mm'):null
                }
              }
            });
          } else {
            this.errorRetrievingTasks = true;
          }
        })
        .catch((e) => {
          console.log("error here", e);
          this.errorRetrievingTasks = true;
        });
      console.log(this.sidepanelTasks)
    },
    async getCalendarTasks(newMonth = null) {
      let monthMoment = newMonth ? moment(newMonth, 'YYYY-MM') : moment();

      let startDate = monthMoment.clone().startOf('month').subtract(7, 'days').format('YYYY-MM-DD');
      let endDate = monthMoment.clone().endOf('month').add(7, 'days').format('YYYY-MM-DD');

      let url = `/api/tasks/user/events/${this.user._id}/1?start=${startDate}&end=${endDate}`;

      try {
        let response = await axios.get(url);
        if (response && response.status === 200) {
          this.calendarTasks = response.data.map(task => {
            return {
              ...task,
              dates: {
                ...task.dates,
                startTime: task.dates.startTime? moment(task.dates.startDate).format('HH:mm'):null,
                endTime: task.dates.endTime? moment(task.dates.endDate).format('HH:mm'):null
              }
            }
          });
        } else {
          console.log("Couldn't get tasks");
        }
        if (this.user.nylas && !this.user.nylasSynced) {
          const accessToken = await this.getNylasAccessToken();
          if (accessToken) {
            const token = accessToken;
            const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            await axios.post(`/api/tasks/nylas/all`, { token, userTimezone });
          }
        }
        if(this.user.nylas) {
          response = await axios.get(`/api/tasks/user/events/${this.user._id}`);
          if (response && response.status === 200) {
            this.calendarTasks = this.deduplicateEvents(this.calendarTasks, response.data.map(task => {
              return {
                ...task,
                dates: {
                  ...task.dates,
                  startTime: moment(task.dates.startDate).format('HH:mm'),
                  endTime: moment(task.dates.endDate).format('HH:mm')
                }
              }
            }));
            } else {
              console.log("Couldn't get tasks");
            }
        }
      } catch (error) {
        console.error("Error fetching calendar tasks:", error);
      }
    },
    deduplicateEvents(existingEvents, newEvents) {
      const uniqueEvents = new Map(existingEvents.map(event => [event._id, event]));

      newEvents.forEach(event => {
          uniqueEvents.set(event._id, event);
      });

      return Array.from(uniqueEvents.values());
    },
    async getSharedResources() {
      await axios.get("/api/admin/get-shared-resources").then((response) => {
        if (
          response &&
          response.status &&
          response.status === 200 &&
          response.data
        ) {
          const taskType = this.taskTypes.find(
            (taskType) => taskType.value === "resource"
          );
          taskType.dropdownItems = response.data;
          this.sharedResourceTasks = response.data;
        }
      });
    },
    viewTaskUpdated(task) {
      if (!this.sidepanelTasks) return;
      this.sidepanelTasks.forEach((oldTask) => {
        if (oldTask._id === task._id) {
          oldTask.isCompleted = task.isCompleted;
        }
      });
    },
    handleMonthChange(newMonth) {
      this.currentMonth = newMonth;
    },
  },
};
</script>

<style scoped></style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.emitCloseWidget,
      expression: "emitCloseWidget"
    }],
    ref: "tasksViewMore",
    staticClass: "tasks-view-more-container",
    style: _vm.style
  }, [_vm._l(_vm.allDayTasks, function (task) {
    return _c('div', {
      key: task._id,
      staticClass: "task-view-more-task",
      style: {
        backgroundColor: require('@/components/CalendarView/taskTypes').find(function (type) {
          return type.value === task.type;
        }).color
      },
      on: {
        "click": function ($event) {
          return _vm.emitOpenTaskViewWidget(task);
        }
      }
    }, [task.dates.startTime ? _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "font-weight": "bolder",
        "white-space": "nowrap"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatTime(task.dates.startTime)) + " ")]) : _vm._e(), _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "display": "block",
        "white-space": "nowrap",
        "text-overflow": "ellipsis",
        "overflow": "hidden"
      }
    }, [_vm._v(" " + _vm._s(task.type === "open_house" ? _vm.$t("OpenHouse") : task.content) + " ")])]);
  }), _vm._l(_vm.tasks, function (task) {
    return _c('div', {
      key: task._id,
      staticClass: "task-view-more-task",
      style: {
        backgroundColor: require('@/components/CalendarView/taskTypes').find(function (type) {
          return type.value === task.type;
        }).color
      },
      on: {
        "click": function ($event) {
          return _vm.emitOpenTaskViewWidget(task);
        }
      }
    }, [task.dates.startTime ? _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "font-weight": "bolder",
        "white-space": "nowrap"
      }
    }, [_vm._v(" " + _vm._s(_vm.formatTime(task.dates.startTime)) + " ")]) : _vm._e(), _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "display": "block",
        "white-space": "nowrap",
        "text-overflow": "ellipsis",
        "overflow": "hidden"
      }
    }, [_vm._v(" " + _vm._s(task.type === "open_house" ? _vm.$t("OpenHouse") : task.content) + " ")])]);
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm$taskType$dropdown, _vm$taskType$dropdown2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-draggable-resizable', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.closeWidget,
      expression: "closeWidget"
    }],
    staticClass: "widget-container",
    attrs: {
      "z": 99,
      "resizable": false,
      "drag-handle": ".drag-area",
      "h": "auto"
    }
  }, [!_vm.loading && _vm.task ? _c('div', {
    staticClass: "task-view-widget-header drag-area",
    style: {
      backgroundColor: _vm.taskType.headerColor
    }
  }, [_c('img', {
    attrs: {
      "width": "104",
      "height": "11",
      "src": require("@/assets/images/menu.svg"),
      "alt": ""
    }
  }), _c('div', {
    staticStyle: {
      "display": "flex",
      "justify-content": "flex-end",
      "gap": "12px",
      "flex-wrap": "nowrap"
    }
  }, [_vm.task.category && _vm.task.category !== 'open_house' && _vm.task.category !== 'event' && _vm.task.category !== 'resource' ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-wrap": "nowrap",
      "gap": "4px"
    }
  }, [!_vm.task.isCompleted ? [_c('img', {
    attrs: {
      "height": "16",
      "width": "16",
      "src": require('@/assets/images/checkmark_16_16.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "link-small",
    on: {
      "click": _vm.changeTaskCompleteStatus
    }
  }, [_vm._v(_vm._s(_vm.$t("MarkCompleted")))])] : _vm._e(), _vm.task.isCompleted ? [_c('p', {
    staticClass: "link-small",
    on: {
      "click": _vm.changeTaskCompleteStatus
    }
  }, [_vm._v(_vm._s(_vm.$t("Done")))])] : _vm._e()], 2) : _vm._e(), _vm.taskType.value === 'open_house' ? _c('p', {
    staticClass: "link-small",
    on: {
      "click": _vm.addParticipants
    }
  }, [_vm._v(_vm._s(_vm.task.numberOfParticipants ? _vm.task.numberOfParticipants + " " + _vm.$t("Participants") : _vm.$t("AddParticipants")))]) : _vm._e(), _vm.task.category !== 'resource' ? _c('p', {
    staticClass: "link-small",
    on: {
      "click": _vm.editTask
    }
  }, [_vm._v(_vm._s(_vm.$t("Edit")))]) : _vm._e(), _c('p', {
    staticClass: "link-small",
    on: {
      "click": _vm.openDeleteModal
    }
  }, [_vm._v(_vm._s(_vm.$t("Delete")))])])]) : _vm._e(), !_vm.loading && _vm.task ? [_c('div', {
    staticClass: "task-view-widget-body"
  }, [_c('div', {
    staticClass: "task-view-widget-content",
    class: {
      'open-house-content': _vm.taskType.value === 'open_house'
    }
  }, [_vm.taskType.value !== 'generic' ? _c('div', {
    staticClass: "content-small task-view-widget-label",
    style: {
      backgroundColor: _vm.taskType.color
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(_vm.taskType.translation)) + " ")]) : _vm._e(), _c('div', {
    staticClass: "task-view-widget-content-title"
  }, [_vm.task.icon || _vm.task.category === 'resource' ? _c('img', {
    attrs: {
      "height": "20",
      "width": "20",
      "src": require(`@/assets/images/${_vm.getTaskIconName()}`)
    }
  }) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column",
      "gap": "4px"
    }
  }, [_c('div', {
    staticClass: "task-view-widget-content-header",
    class: {
      'open-house-gap': _vm.task.category === 'open_house'
    }
  }, [_vm._v(_vm._s(_vm.task.content ? _vm.task.content : _vm.task.linkedProject ? _vm.task.linkedProject.name : _vm.task.linkedDevelopment.name))]), _vm.taskType.value === 'resource' ? _c('div', {
    staticClass: "content-small",
    staticStyle: {
      "color": "#75787a"
    }
  }, [_vm._v(_vm._s((_vm$taskType$dropdown = _vm.taskType.dropdownItems) === null || _vm$taskType$dropdown === void 0 ? void 0 : (_vm$taskType$dropdown2 = _vm$taskType$dropdown.find(function (item) {
    return item._id === _vm.task.resourceId;
  })) === null || _vm$taskType$dropdown2 === void 0 ? void 0 : _vm$taskType$dropdown2.description))]) : _vm._e()])]), Object.values(_vm.task.dates).some(function (value) {
    return value !== null;
  }) || _vm.task.linkedProject && _vm.task.linkedProject.name && _vm.task.linkedProject.id || _vm.task.linkedDevelopment && _vm.task.linkedDevelopment.name && _vm.task.linkedDevelopment.id || _vm.task.linkedContact && _vm.task.linkedContact.name && _vm.task.linkedContact.id ? _c('div', {
    staticClass: "task-view-widget-content-details"
  }, [Object.values(_vm.task.dates).some(function (value) {
    return value !== null;
  }) ? _c('div', {
    staticClass: "task-view-widget-content-details-row"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/calendar_20_20.svg')
    }
  }), _c('div', {
    class: _vm.task.access.users[_vm.user._id] ? 'clickable-resource' : '',
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    },
    on: {
      "click": _vm.openResourceParent
    }
  }, [_vm.task.access.users[_vm.user._id] && _vm.task.parentTask ? _c('p', {
    staticClass: "content-semibold"
  }, [_vm._v(_vm._s(_vm.task.parentTask.content))]) : _vm._e(), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.task.dates)))])])]) : _vm._e(), _vm.task.linkedUsers && _vm.task.linkedUsers.length > 0 ? _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "display": "flex",
      "flex-direction": "column",
      "gap": "16px"
    }
  }, _vm._l(_vm.task.linkedUsers, function (user) {
    return _c('div', {
      key: user.id,
      staticClass: "task-view-widget-content-details-row",
      staticStyle: {
        "cursor": "pointer"
      }
    }, [_c('img', {
      attrs: {
        "src": require('@/assets/images/users_16_16.svg')
      }
    }), _c('p', {
      staticClass: "content-normal"
    }, [_vm._v(_vm._s(user.name))])]);
  }), 0) : _vm._e(), _vm.task.linkedProject && _vm.task.linkedProject.name && _vm.task.linkedProject.id ? _c('div', {
    staticClass: "task-view-widget-content-details-row",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.emitOpenSidepanel('project');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/projects.svg')
    }
  }), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.task.linkedProject.name))])]) : _vm._e(), _vm.task.linkedDevelopment && _vm.task.linkedDevelopment.name && _vm.task.linkedDevelopment.id ? _c('div', {
    staticClass: "task-view-widget-content-details-row",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.emitOpenSidepanel('development');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/PropertyDeveloper.svg')
    }
  }), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.task.linkedDevelopment.name))])]) : _vm._e(), _vm.task.linkedContact && _vm.task.linkedContact.name && _vm.task.linkedContact.id ? _c('div', {
    staticClass: "task-view-widget-content-details-row",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.emitOpenSidepanel('contact');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/clients.svg')
    }
  }), _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.task.linkedContact.name))])]) : _vm._e(), _vm.task.resources && _vm.task.resources.length > 0 ? _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "100%",
      "display": "flex",
      "flex-direction": "column",
      "gap": "16px"
    }
  }, _vm._l(_vm.task.resources, function (resource) {
    return _c('div', {
      key: resource.name,
      staticClass: "task-view-widget-content-details-row",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          return _vm.emitOpenTask(resource.taskId);
        }
      }
    }, [_c('img', {
      attrs: {
        "src": require('@/assets/images/pencil_20_20.svg')
      }
    }), _c('div', {
      staticStyle: {
        "display": "flex",
        "flex-direction": "column"
      }
    }, [_c('p', {
      staticClass: "content-normal"
    }, [_vm._v(_vm._s(resource.name))]), resource.description ? _c('p', {
      staticClass: "content-small",
      staticStyle: {
        "color": "#75787a"
      }
    }, [_vm._v(_vm._s(resource.description))]) : _vm._e()])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "task-view-widget-comment-row"
  }, [_c('img', {
    staticStyle: {
      "width": "20px",
      "height": "20px"
    },
    attrs: {
      "src": require("@/assets/images/chat-no-bubble.svg"),
      "alt": ""
    }
  }), !_vm.task.comment && !_vm.editComment ? _c('div', {
    on: {
      "click": _vm.openEditComment
    }
  }, [_c('p', {
    staticClass: "link"
  }, [_vm._v(" + " + _vm._s(_vm.$t("addComment").charAt(0).toUpperCase() + _vm.$t("addComment").slice(1)) + " ")])]) : _vm._e(), _vm.editComment ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.task.comment,
      expression: "task.comment"
    }],
    ref: "commentbox",
    staticClass: "task-textarea content-normal",
    staticStyle: {
      "white-space": "pre-wrap"
    },
    attrs: {
      "id": "textarea",
      "rows": "5"
    },
    domProps: {
      "value": _vm.task.comment
    },
    on: {
      "focusout": _vm.closeEditComment,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.task, "comment", $event.target.value);
      }
    }
  }) : _vm._e(), _vm._v(" "), _vm.task.comment && !_vm.editComment ? _c('div', {
    staticStyle: {
      "display": "flex",
      "flex-direction": "column"
    },
    on: {
      "click": _vm.openEditComment
    }
  }, [_c('div', {
    staticClass: "content-normal",
    staticStyle: {
      "white-space": "pre-wrap",
      "width": "290px",
      "overflow-wrap": "break-word"
    }
  }, [_vm._v(_vm._s(_vm.task.comment))])]) : _vm._e()])]) : _vm._e()]), _c('div', {
    staticClass: "task-view-widget-divider",
    staticStyle: {
      "height": "1px",
      "width": "100%",
      "background": "#E6E8EC"
    }
  }), _c('div', {
    staticClass: "task-view-widget-footer"
  }, [(_vm.task.category === 'task' || _vm.task.category === 'event') && _vm.task.isPrivate ? _c('p', {
    staticClass: "content-normal"
  }, [_vm._v(_vm._s(_vm.$t("Private") + " " + _vm.$t("Entry").toLowerCase()))]) : _vm._e(), _c('div', {
    staticClass: "task-view-widget-footer-content"
  }, [_c('img', {
    attrs: {
      "height": "20",
      "width": "20",
      "src": require('@/assets/images/users.svg'),
      "alt": ""
    }
  }), _c('p', {
    staticClass: "content-normal",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function (event) {
        return _vm.openBrokerDetailPanel(event, _vm.assignedTo._id);
      }
    }
  }, [_vm._v(_vm._s(_vm.assignedTo.name))])])]), _vm.brokerDetailPanelOpened ? _c('BrokerInfoPanel', {
    attrs: {
      "item": _vm.clickedBroker,
      "position": _vm.brokerPanelPosition
    },
    on: {
      "closed": _vm.closeBrokerDetailPanel
    }
  }) : _vm._e()], 1)] : _vm._e(), _vm.loading ? _c('div', {
    staticClass: "task-view-widget-loading-container"
  }, [_c('div', {
    staticClass: "loader"
  })]) : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="calendar-sidepanel">
    <div class="calendar-sidepanel-header">
      <h5 class="calendar-sidepanel-header-title">
        {{ $t("TasksAndEvents") }}
      </h5>
      <BaseButton
        @click="emitOpenTaskAdd"
        :icon-left="'add_16_16.svg'"
        size="small"
        :label="$t('Add')"
      />
    </div>
    <div style="padding-left: 24px; padding-right: 24px">
    <CalendarTypeFilter
        :type="type"
        @selectStatus="filterByType"
      />
    </div>
    <div
      v-if="tasksLoaded && tasks.length > 0 && !sortedEmpty"
      class="calendar-sidepanel-content"
      :key="'calendar-sidepanel-' + htmlKeyUpdater"
    >
      <div
        v-if="overdueTasks.length > 0"
        class="calendar-sidepanel-content-section"
      >
        <div class="label12">{{ $t("Overdue") }}</div>
        <div v-for="task of overdueTasks" :key="task._id">
          <Task
            @addParticipants="emitAddParticipants"
            @openTaskViewWidget="emitOpenTaskViewWidget"
            :task="task"
            :end-date-passed="true"
          />
        </div>
      </div>
      <div
        v-if="noDueDateTasks.length > 0"
        class="calendar-sidepanel-content-section"
      >
        <div class="label12">{{ $t("NoDueDate") }}</div>
        <div v-for="task of noDueDateTasks" :key="task._id">
          <Task
            @addParticipants="emitAddParticipants"
            @openTaskViewWidget="emitOpenTaskViewWidget"
            :task="task"
          />
        </div>
      </div>
      <div
        v-if="todayTasks.length > 0"
        class="calendar-sidepanel-content-section"
      >
        <div class="label12">{{ $t("Today") }}</div>
        <div v-for="task of todayTasks" :key="task._id">
          <Task
            @addParticipants="emitAddParticipants"
            @openTaskViewWidget="emitOpenTaskViewWidget"
            :task="task"
          />
        </div>
      </div>
      <div
        v-if="tomorrowTasks.length > 0"
        class="calendar-sidepanel-content-section"
      >
        <div class="label12">{{ $t("Tomorrow") }}</div>
        <div v-for="task of tomorrowTasks" :key="task._id">
          <Task
            @addParticipants="emitAddParticipants"
            @openTaskViewWidget="emitOpenTaskViewWidget"
            :task="task"
          />
        </div>
      </div>
      <div
        v-if="upcomingTasks.length > 0"
        class="calendar-sidepanel-content-section"
      >
        <div class="label12">{{ $t("DateNext") }}</div>
        <div v-for="task of upcomingTasks" :key="task._id">
          <Task
            @addParticipants="emitAddParticipants"
            @openTaskViewWidget="emitOpenTaskViewWidget"
            :task="task"
          />
        </div>
      </div>
    </div>
    <div
      v-if="
        (tasksLoaded &&
        tasks.length < 1 &&
        !errorGettingTasks &&
        !errorRetrievingTasks && !sortedEmpty)
      "
      class="calendar-sidepanel-content-empty"
    >
      <div class="subheading">
        {{ $t("NoTasksEvents") }}
      </div>
      <div class="content-small">
        {{ $t("Click") }}
        <span style="font-weight: bold; padding: 0 4px">
          + {{ $t("Add") }}
        </span>
        {{ $t("ToAddNewTaskOrEvent") }}
      </div>
    </div>
     <div
      v-if="sortedEmpty"
      class="calendar-sidepanel-content-empty"
    >
      <div class="subheading">
        {{ getEmptyMessage(type) }}
      </div>
      <div class="content-small">
        {{ $t("Click") }}
        <span style="font-weight: bold; padding: 0 4px">
          + {{ $t("Add") }}
        </span>
        {{ getEmptyMessageType(type) }}
      </div>
    </div>
    <div
      v-if="
        tasksLoaded &&
        tasks.length < 1 &&
        (errorGettingTasks || errorRetrievingTasks)
      "
      class="calendar-sidepanel-content-empty"
    >
      <div class="subheading">
        {{ $t("SomethingWentWrong") }}
      </div>
      <BaseButton @click="emitTasksReload" :label="$t('TryAgain')" />
    </div>
    <div v-if="!tasksLoaded" class="calendar-sidepanel-content-loading">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/common/BaseButton";
import Task from "@/components/CalendarView/Task";
import moment from "moment";
import CalendarTypeFilter from '@/components/CalendarView/CalendarTypeFilter.vue';

export default {
  name: "CalendarSidePanel",
  props: ["tasks", "errorRetrievingTasks"],
  components: { BaseButton, Task, CalendarTypeFilter },
  data() {
    return {
      tasksLoaded: false,
      overdueTasks: [],
      noDueDateTasks: [],
      todayTasks: [],
      tomorrowTasks: [],
      upcomingTasks: [],
      errorGettingTasks: false,
      loading: false,
      htmlKeyUpdater: 0,
      type: '',
      sortedEmpty: false
    };
  },
  watch: {
    tasks() {
      this.htmlKeyUpdater++;
      this.filterByType(this.type)
      //this.divideTasksToGroups(this.tasks);
    },
    errorRetrievingTasks(value) {
      this.errorGettingTasks = value;
      this.filterByType(this.type)
      //this.divideTasksToGroups(this.tasks);
    },
  },
  methods: {
    filterByType(selectedType){
      this.sortedEmpty = false
      this.type = selectedType      
      if (selectedType !== '') {
        let sortedTasks = this.tasks.filter((task) => task.category === selectedType)
        this.divideTasksToGroups(sortedTasks);
        if (!sortedTasks.length) {
          this.sortedEmpty = true
        }
      } else {
        this.divideTasksToGroups(this.tasks)
      }
    },
    divideTasksToGroups(tasks) {
      this.tasksLoaded = false;
      this.clearTaskGroups();
      tasks.forEach((task) => {
        const isTaskOverdue = this.isTaskOverdue(task);
        if (isTaskOverdue && task.category === 'event') {
          return;
        }
        if (task.dates.endDate) {
          if (isTaskOverdue) {
            this.overdueTasks.push(task);
            return;
          }
          if (this.isToday(task)) {
            this.todayTasks.push(task);
          } else if (this.isTomorrow(task)) {
            this.tomorrowTasks.push(task);
          } else {
            this.upcomingTasks.push(task);
          }
        } else {
          this.noDueDateTasks.push(task);
        }
      });
      this.sortTasks();
      this.tasksLoaded = true;
    },
    sortTasks() {
      this.overdueTasks = this.overdueTasks.sort(
        (a, b) =>
          moment(a.dates.endDate).valueOf() - moment(b.dates.endDate).valueOf()
      );
      this.upcomingTasks = this.upcomingTasks.sort(
        (a, b) =>
          moment(a.dates.endDate).valueOf() - moment(b.dates.endDate).valueOf()
      );
      this.todayTasks = this.todayTasks.sort(
        (a, b) =>
          moment(a.dates.endDate).valueOf() - moment(b.dates.endDate).valueOf()
      );
      this.tomorrowTasks = this.tomorrowTasks.sort(
        (a, b) =>
          moment(a.dates.endDate).valueOf() - moment(b.dates.endDate).valueOf()
      );
    },
    isTaskOverdue(task) {
      const currentDateTime = moment();
      return moment(task.dates.endDate).isBefore(currentDateTime);
    },
    isToday(task) {
      const startOfToday = moment().startOf("day").format();
      const endOfToday = moment().endOf("day").format();
      return !!(
        moment(task.dates.endDate).isSameOrAfter(startOfToday) &&
        moment(task.dates.endDate).isSameOrBefore(endOfToday)
      );
    },
    isTomorrow(task) {
      const date = moment(task.dates.endDate);
      const tomorrow = moment().add(1, "day").endOf("day");
      if (date < tomorrow) return true;
    },
    getEmptyMessage(type) {
      if (type === 'task') {
        return this.$t("NoTasksToShow")
      } else if (type === 'event') {
        return this.$t("NoEventsToShow")
      } else {
        return this.$t("NoOpenHousesToShow")
      }
    },
    getEmptyMessageType(type) {
      if (type === 'task') {
        return this.$t("ToAddNewTask")
      } else if (type === 'event') {
        return this.$t("ToAddNewEvent")
      } else {
        return this.$t("ToAddNewOpenHouse")
      }
    },
    clearTaskGroups() {
      this.overdueTasks = [];
      this.noDueDateTasks = [];
      this.upcomingTasks = [];
      this.todayTasks = [];
      this.tomorrowTasks = [];
    },
    emitTasksReload() {
      this.tasksLoaded = false;
      this.$emit("getTasks");
    },
    emitOpenTaskAdd() {
      this.$emit("addTask");
    },
    emitOpenTaskViewWidget(value) {
      this.$emit("openTaskViewWidget", value);
    },
    emitAddParticipants(value) {
      this.$emit("addParticipants", value);
    },
  },
};
</script>

<style scoped>
.calendar-sidepanel {
  background-color: white;
  border-radius: 8px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-right: 80px;
  width: 400px;
  flex-shrink: 0;
}

.calendar-sidepanel-header {
  display: flex;
  flex-wrap: nowrap;
  gap: 32px;
  padding-right: 24px;
  padding-left: 24px;
}

.calendar-sidepanel-header-title {
  width: 100%;
}

.calendar-sidepanel-content-empty {
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  height: 100%;
  align-items: center;
}

.calendar-sidepanel-content-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.calendar-sidepanel-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow-y: auto;
}

.calendar-sidepanel-content-section {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 24px;
  padding-left: 24px;
}

.calendar-sidepanel-content-section:last-child {
  margin-bottom: 2px;
}
</style>
